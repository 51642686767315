// components/Hero.tsx
import React from 'react';
import {Box, Container, Typography, Button} from '@mui/material';

import neurobanner from '../ai-image-large-small.jpeg';

const Hero: React.FC = () => {
  return (
    <Box sx={{backgroundImage: `url(${neurobanner})`, backgroundPosition: "center", backgroundColor: '#444', padding: '64px 0 36px'}}>

      <Container maxWidth="sm" sx={{paddingTop: 5, paddingBottom: 5, textAlign: 'center', backgroundColor: "rgba(55, 55, 55, 0.8)"}}>
        <Typography component="h1" variant="h2" color="white" gutterBottom>
          NineLives.ai
        </Typography>
        <Typography variant="h5" color="white" paragraph>
          Pioneering the future of neurotechnology by bridging the gap between brain and machine.
        </Typography>
        <div style={{marginTop: '32px'}}>
          <Box display="flex" justifyContent="center">
            <Button variant="contained" color="primary">
              Learn More
            </Button>
          </Box>
        </div>
      </Container>
    </Box>
  );
};

export default Hero;

