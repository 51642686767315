// components/Contact.tsx
import React from 'react';
import {Box, Typography, Paper, Link} from '@mui/material';

const Contact: React.FC = () => {
  return (
    <Box my={4}>
      <Paper elevation={3} style={{padding: '20px', textAlign: 'center'}}>
        <Typography variant="h4" gutterBottom>
          Contact Us
        </Typography>
        <Typography variant="body1">
          Questions? Reach out to our team and we'll get back to you as soon as we can.
        </Typography>
        <Box mt={2}>
          <Link href="mailto:team@ninelives.ai" color="secondary">
            team@ninelives.ai
          </Link>
        </Box>
      </Paper>
    </Box>
  );
};

export default Contact;

