// components/About.tsx
import React from 'react';
import { Box, Typography, Paper } from '@mui/material';

const About: React.FC = () => {
  return (
    <Box my={4}>
      <Paper elevation={3} style={{ padding: '20px' }}>
        <Typography variant="h4" gutterBottom>
          About Us
        </Typography>
        <Typography variant="body1">
          Located in the heart of New York, Nine Lives AI is a forward-thinking company dedicated to building external brain-computer interface (BCI) devices. We are at the forefront of the innovative product space, leveraging cutting-edge technology to develop solutions that integrate seamlessly with human cognition. Our mission is to enhance the symbiosis between humans and machines, creating a future where technology expands the capabilities of the human mind.
        </Typography>
      </Paper>
    </Box>
  );
};

export default About;

