// components/Team.tsx
import React from 'react';
import {Box, Typography, Paper} from '@mui/material';

const Team: React.FC = () => {
  return (
    <Box my={4}>
      <Paper elevation={3} style={{padding: '20px'}}>
        <Typography variant="h4" gutterBottom>
          Our Team
        </Typography>
        <Typography variant="body1">
          The New York BCI Lab boasts a diverse team composed of Ivy League graduates and seasoned entrepreneurs with a wealth of experience across the technology sector. Our team members are pioneers in their respective fields, bringing together expertise from neurotechnology, artificial intelligence, and engineering to push the boundaries of what's possible in brain-computer interfacing.
        </Typography>
      </Paper>
    </Box>
  );
};

export default Team;

