// components/Research.tsx
import React from 'react';
import {Box, Typography, Paper} from '@mui/material';

const Research: React.FC = () => {
  return (
    <Box my={4}>
      <Paper elevation={3} style={{padding: '20px'}}>
        <Typography variant="h4" gutterBottom>
          Our Research
        </Typography>
        <Typography variant="body1">
          As an emergent BCI lab, we are currently in the foundational stages of our research. Our efforts are focused on developing groundbreaking technologies in the field of brain-computer interfaces. At this time, our research is conducted under strict confidentiality to maintain the integrity and privacy of our work. We are committed to advancing the field of BCI and look forward to sharing our discoveries with the scientific community at the appropriate time.
        </Typography>
      </Paper>
    </Box>
  );
};

export default Research;

