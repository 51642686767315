import React from 'react';
import {forwardRef, useRef, useState, useMemo, useEffect, useCallback} from "react";
import {createTheme, ThemeProvider, PaletteMode, CssBaseline, Container, AppBar, Toolbar, Typography, Box} from '@mui/material';
import {deepmerge} from "@mui/utils";


// Import your custom components
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import About from './components/About';
import Research from './components/Research';
import Team from './components/Team';
import Contact from './components/Contact';


import {lightTheme, darkTheme, universal, postUniversal, ColorContext} from './theme';



function App() {

  const [mode, setMode] = useState<PaletteMode>("light");


  function mergeThemes() {
    return postUniversal(createTheme(deepmerge((mode === "light" ? lightTheme : darkTheme), universal)))
  }
  const theme = useMemo(
    mergeThemes,
    [mode]
  );

  function colorCalc() {
    return {
      toggleColorMode: () => {
        setMode((prevMode: PaletteMode) =>
          prevMode === "light" ? "dark" : "light"
        );
      },
      color: mode,
    };
  }


  const colorMode = useMemo(
    colorCalc,
    [mode]
  );




  return (
    <>

      <ColorContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>

          <CssBaseline />
          <AppBar position="relative">
            <Toolbar>
              <Typography variant="h6" color="inherit" noWrap>
                NineLives.ai
              </Typography>
            </Toolbar>
          </AppBar>
          <main>
            <Box sx={{
              display: "flex",
              flexDirection: "column",
              alignContent: "center",

            }}>
              {/* Hero unit */}
              <Hero />
              <Box maxWidth="md" sx={{alignSelf: "center", alignContent: "start", flexWrap: "wrap", display: "flex", justify: "space-around"}}>
                {/* About Section */}
                <About />
                {/* Research Section */}
                <Research />
                {/* Team Section */}
                <Team />
              </Box>
              {/* Contact Section */}
              <Box maxWidth="md" sx={{alignSelf: "center", display: "flex", justify: "space-around"}}>
                <Contact />
              </Box>
            </Box>
          </main>
          <footer>
            <Box mt={5} sx={{alignSelf: "center"}}>
              <Typography variant="body2" color="textSecondary" align="center">
                © {new Date().getFullYear()} Nine Lives AI LLC
              </Typography>
              <Typography variant="body2" color="textSecondary" align="center">
                <a href="https://www.freepik.com/free-photo/artificial-intelligence-system-using-complex-language-code-deep-learning-neural-network-programming-model-solving-operations-using-massive-computational-power-3d-render-animation_57197807.htm#query=brain%20computer%20interface&position=1&from_view=keyword&track=ais">Image by DCStudio</a> on Freepik
              </Typography>

            </Box>
          </footer>
        </ThemeProvider>
      </ColorContext.Provider>
    </>
  );
}

export default App;

